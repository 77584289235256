import { logout as keycloakLogout } from '../../../src/keycloak'
import  useStore  from '../../data/state/zustand';


const clearCache = () => {
  return new Promise((resolve, reject) => {
    window.localStorage.removeItem("hologic-storage");
    return resolve(true);
  });
};

const reloadLocation = () => {
  window.location.reload(true)
}

const logout = () => {
  console.log('loging out');
  clearCache().then(async (res) => {
    window.location.hash = '#/';    // window.location.reload()
    await keycloakLogout()
    reloadLocation()
    useStore.getState().setNestedProperty(["authTokenInfo"], null)
    useStore.getState().setNestedProperty(["keycloak"], {authenticated:false})

  })
};

export { logout };