import { multipageLoad, rest } from '../restWrapper';
import _ from 'lodash';
import pubsub from '../pubsub';
import * as Sentry from '@sentry/react';
import socketClient from '@data/sockets';
import { isFeatureEnabled } from '@components/FeatureFlag';
import { reloadReservations } from './loaders';
import useStore from '@data/state/zustand';
import { getTaskHeaderForDate, getTaskItemsByTaskHeaderId } from '@pages/householdNew/api';

pubsub.on('loginSuccess', async (email) => {
    await loadStartupModelData(socketClient);
    Sentry.configureScope((scope) => {
        scope.setUser({ email: email });
    });
});

const reloadInitialData = async () => {
    const user = useStore.getState().authTokenInfo?.user;

    const permissions = await rest('/getLoggedUserPerms');
    useStore.getState().setNestedProperty(['state', 'userPermissions'], permissions);

    const pkeyMap = await rest('/pkeyMap');
    useStore.getState().setNestedProperty(['pkeyMap'], pkeyMap);

    const licence = await rest('/licence');
    useStore.getState().setNestedProperty(['licence'], licence);

    const FeatureFlag = await rest('/FeatureFlag?rows=300&page=0');
    useStore.getState().setNestedProperty(['model', 'FeatureFlag'], FeatureFlag);

    resetCompanyNameFromLicenceInformation(licence);

    if (permissions?.readModels.includes('RoomInfo')) {
        const roomInfo = await rest('/RoomInfo?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'RoomInfo'], roomInfo);
    }

    if (permissions?.readModels.includes('DoorAccessPoint')) {
        const doorAccessPoint = await rest('/DoorAccessPoint?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'DoorAccessPoint'], doorAccessPoint);
    }

    if (permissions?.readModels.includes('RoomAttribute')) {
        const roomAttributes = await rest('/RoomAttribute?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'RoomAttribute'], roomAttributes);
    }

    if (permissions?.readModels.includes('SmartRoomController')) {
        const SmartRoomController = await rest('/SmartRoomController?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'SmartRoomController'], SmartRoomController);
    }

    if (permissions?.readModels.includes('WhiteList')) {
        const whitelist = await rest('/WhiteList?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'WhiteList'], whitelist);
    }

    if (permissions?.readModels.includes('Tax')) {
        const tax = await rest('/Tax?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'Tax'], tax);
    }

    if (permissions?.readModels.includes('Currency')) {
        const currency = await rest('/Currency?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'Currency'], currency);
    }

    if (permissions?.readModels.includes('SRCProxy')) {
        const SRCProxy = await rest('/SRCProxy?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'SRCProxy'], SRCProxy);
    }

    if (permissions?.readModels.includes('AccommodationPriceList')) {
        const AccommodationPriceList = await rest('/AccommodationPriceList?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'AccommodationPriceList'], AccommodationPriceList);
    }

    if (permissions?.readModels.includes('RoomEventTicket')) {
        const RoomEventTicket = await rest('/getActiveRoomEventTickets');
        useStore.getState().setNestedProperty(['model', 'RoomEventTicket'], RoomEventTicket);
    }

    if (permissions?.readModels.includes('SosResponse')) {
        const activeSosResponses = await rest('/getActiveSosResponses');
        useStore.getState().setNestedProperty(['model', 'SosResponse'], activeSosResponses);
    }

    if (permissions?.readModels.includes('RequiredVisaMap')) {
        const requiredVisaMap = await rest('/RequiredVisaMap');
        useStore.getState().setNestedProperty(['model', 'RequiredVisaMap'], requiredVisaMap);
    }

    if (permissions?.readModels.includes('PosTerminal')) {
        const posTerminal = await rest('/PosTerminal?rows=1000&page=0');
        const enabledPosTerminals = posTerminal?.filter((t) => t?.isEnabled);
        useStore
            .getState()
            .setNestedProperty(['model', 'PosTerminal'], enabledPosTerminals?.length > 0 ? enabledPosTerminals : []);
    }

    if (permissions?.readModels.includes('DocumentScan')) {
        const docScan = await rest('/DocumentScan?rows=20&page=0&by=id&order=desc');
        useStore.getState().setNestedProperty(['model', 'DocumentScan'], docScan);
    }

    if (permissions?.readModels.includes('PosDisplayConfig')) {
        const posDisplayConfig = await rest('/PosDisplayConfig?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'PosDisplayConfig'], posDisplayConfig);
    }

    if (permissions?.readModels.includes('LoyaltyProgram')) {
        const loyaltyPrograms = await rest('/LoyaltyProgram?rows=500&page=0');
        useStore.getState().setNestedProperty(['model', 'LoyaltyProgram'], loyaltyPrograms);
    }

    if (permissions?.readModels.includes('Warehouse')) {
        const warehouses = await rest('/Warehouse?rows=500&page=0');
        useStore.getState().setNestedProperty(['model', 'Warehouse'], warehouses);
    }

    if (
        permissions?.readModels.includes('TaskManagementHeader') &&
        permissions?.readModels.includes('TaskManagementItem')
    ) {
        const _taskHeader = await getTaskHeaderForDate(new Date().getTime());
        if (_taskHeader) {
            const _todaysTaskItems = await getTaskItemsByTaskHeaderId(_taskHeader.id);
            useStore.getState().setNestedProperty(['model', 'TaskManagementHeader'], [_taskHeader]);
            useStore.getState().setNestedProperty(['model', 'TaskManagementItem'], _todaysTaskItems);
        }
    }
    if (permissions?.readModels.includes('ChannelManagerMap') && isFeatureEnabled('channelManager')) {
        const channelManagement = await rest('/getGeneralSettingByKey/channelManagement');
        const parsedChm = channelManagement?.params ? JSON.parse(channelManagement.params) : null;
        if (parsedChm && parsedChm['wuBook']?.enabled) {
            const chm = await rest('/ChannelManagerMap?rows=1000&page=0');

            let _chmProviderRooms = chm.filter(
                (c) => c.model === 'RoomInfo' && c.remoteId && c.provider === 'wuBook' && !c.parentId
            );

            if (_chmProviderRooms?.length === 0) {
                await rest('/fetchChmMappingData/wuBook', 'GET');
                const chmData = await rest('/ChannelManagerMap?rows=1000&page=0');
                useStore.getState().setNestedProperty(['model', 'ChannelManagerMap'], [...chmData]);
            } else {
                useStore.getState().setNestedProperty(['model', 'ChannelManagerMap'], chm);
            }
        }
    }
    if (permissions?.readModels.includes('PersistentNotificationSystem')) {
        const persistentNotificationSystems = await rest('/getPersistentNotificationSystems');
        useStore.getState().setNestedProperty(['model', 'PersistentNotificationSystem'], persistentNotificationSystems);
    }

    if (user?.role === 'admin' || user?.role === 'superAdmin' || user?.role === 'posOnprem') {
        let roles = await rest('/getRoles');
        const licence = useStore.getState().licence

        const isPosOnPremIncluded = licence?.licence?.decodedLicence?.pack?.features?.includes('posOnPrem')
        if (!isPosOnPremIncluded) {
            roles = roles.filter((r) => r.key !== 'posOnprem');
        }

        if (user?.role === 'posOnprem') {
            roles = roles.filter(
                (r) => r.key === 'posBartender' || r.key === 'posKitchen' || r.key === 'posSupervisor'
            );
        }

        useStore.getState().setNestedProperty(['model', 'Role'], roles);
    }

    const CondoOwner = await rest('/getCondoOwners');
    useStore.getState().setNestedProperty(['model', 'CondoOwner'], CondoOwner);

    const version = await rest('/version');
    useStore.getState().setNestedProperty(['version'], version);

    const SRCEnums = await rest('/SRCEnums');
    useStore.getState().setNestedProperty(['SRCEnums'], { ...SRCEnums });

    await reloadReservations();
};

const reloadShortData = async () => {
    const permissions = await rest('/getLoggedUserPerms');
    useStore.getState().setNestedProperty(['state', 'userPermissions'], permissions);

    const pkeyMap = await rest('/pkeyMap');
    useStore.getState().setNestedProperty(['pkeyMap'], pkeyMap);

    const licence = await rest('/licence');
    useStore.getState().setNestedProperty(['licence'], licence);

    const FeatureFlag = await rest('/FeatureFlag?rows=300&page=0');
    useStore.getState().setNestedProperty(['model', 'FeatureFlag'], FeatureFlag);

    if (permissions?.readModels.includes('PosTerminal')) {
        const posTerminal = await rest('/PosTerminal?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'PosTerminal'], posTerminal);
    }
    if (permissions?.readModels.includes('RoomInfo')) {
        const roomInfo = await rest('/RoomInfo?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'RoomInfo'], roomInfo);
    }
    if (permissions?.readModels.includes('Currency')) {
        const currency = await rest('/Currency?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'Currency'], currency);
    }
    if (permissions?.readModels.includes('SRCProxy')) {
        const SRCProxy = await rest('/SRCProxy?rows=1000&page=0');
        useStore.getState().setNestedProperty(['model', 'SRCProxy'], SRCProxy);
    }
    const version = await rest('/version');
    useStore.getState().setNestedProperty(['version'], version);

    const SRCEnums = await rest('/SRCEnums');
    useStore.getState().setNestedProperty(['SRCEnums'], { ...SRCEnums });
};

const resetCompanyNameFromLicenceInformation = (licence) => {
    if (licence && licence.licence && licence.licence.isValid) {
        const licencedCompanyName = licence.licence.decodedLicence.companyName;
        const companyInfo = useStore.getState().companyInfo ? { ...useStore.getState().companyInfo } : {};

        if (companyInfo !== null) {
            companyInfo.name = licencedCompanyName;
            useStore.getState().setNestedProperty(['companyInfo'], companyInfo);
        }
    }
};

const unprotectedLoadGeneralSettings = async () => {
    const locale = await rest('/getGeneralSettingByKey/locale');
    const otherSettings = await rest('/getGeneralSettingByKey/otherSettings');
    const companyInfo = await rest('/getGeneralSettingByKey/companyInfo'); //company name are contained in licence file also
    const defaultCurrency = await rest('/getGeneralSettingByKey/defaultCurrency');
    const IOTatewayIntegration = await rest('/getGeneralSettingByKey/IOTatewayIntegration'); ///getGeneralSettingByKey/IOTatewayIntegration
    const softwareFiscal = await rest('/getGeneralSettingByKey/fiscalCertificate'); ///getGeneralSettingByKey/IOTatewayIntegration
    const fiscalGeneralSettings = await rest('/getGeneralSettingByKey/fiscalGeneralSettings'); ///getGeneralSettingByKey/IOTatewayIntegration
    const invoicingSettings = await rest('/getGeneralSettingByKey/invoicingSettings'); ///getGeneralSettingByKey/IOTatewayIntegration
    const hiddenDocumentColumns = await rest('/getGeneralSettingByKey/hiddenDocumentColumns');
    const offlineThermalPrinterInfo = await rest('/getGeneralSettingByKey/offlineThermalPrinterInfo');
    // const registerENU = await rest('/getGeneralSettingByKey/registerENU'); ///getGeneralSettingByKey/IOTatewayIntegration

    if (locale) {
        if (useStore.getState().locale !== locale.params) {
            useStore.getState().setNestedProperty(['locale'], locale.params);
        }
    }

    if (companyInfo) {
        useStore.getState().setNestedProperty(['companyInfo'], JSON.parse(companyInfo.params));
    }
    if (defaultCurrency) {
        useStore.getState().setNestedProperty(['defaultCurrency'], defaultCurrency.params);
    }
    if (IOTatewayIntegration) {
        try {
            useStore.getState().setNestedProperty(['IOTatewayIntegration'], JSON.parse(IOTatewayIntegration.params));
        } catch (err) {
            console.log(err);
        }
    }
    if (otherSettings) {
        useStore.getState().setNestedProperty(['otherSettings'], JSON.parse(otherSettings.params));
    }
    if (softwareFiscal) {
        useStore.getState().setNestedProperty(['fiscal', 'softwareFiscal'], JSON.parse(softwareFiscal.params));
    }
    if (fiscalGeneralSettings) {
        useStore
            .getState()
            .setNestedProperty(['fiscal', 'fiscalGeneralSettings'], JSON.parse(fiscalGeneralSettings.params));
    }
    if (invoicingSettings) {
        useStore.getState().setNestedProperty(['invoicingSettings'], JSON.parse(invoicingSettings.params));
    }
    if (hiddenDocumentColumns) {
        useStore.getState().setNestedProperty(['hiddenDocumentColumns'], JSON.parse(hiddenDocumentColumns.params));
    }
    if (offlineThermalPrinterInfo) {
        useStore
            .getState()
            .setNestedProperty(['offlineThermalPrinterInfo'], JSON.parse(offlineThermalPrinterInfo.params));
    }
};

const loadStartupModelData = async (socketClient, shortData = false) => {
    try {
        try {
            await unprotectedLoadGeneralSettings();
        } catch (err) {
            console.log('general settings load error', err);
        }
        const about = await rest('/about');
        useStore.getState().setNestedProperty(['about'], { ...about });

        const user = useStore.getState().authTokenInfo?.user;
        const token = useStore.getState().authTokenInfo?.token;
        const isSessionAuthenticated = useStore.getState().keycloak;

        if ((user && token) || isSessionAuthenticated?.authenticated === true) {
            socketClient.initSocket(token ? token : isSessionAuthenticated.token);
            socketClient.subscribeUser(user && user.uuid ? user.uuid : isSessionAuthenticated.sessionId);
            socketClient.on('socket_connected', async () => {
                //socket connection is mandatory
                console.log('SOCKET CONNECTED');
                if (shortData) {
                    await reloadShortData();
                } else {
                    await reloadInitialData();
                }
                useStore
                    .getState()
                    .setNestedProperty(['socketConnection', 'connectingMessage'], 'CONNECTION ESTABLISHED');

                useStore.getState().setNestedProperty(['socketConnection', 'heading'], 'please wait');

                setTimeout(() => {
                    useStore.getState().setNestedProperty(['socketConnection', 'showConnectingScreen'], false);
                }, 500);
            });
        } else {
            useStore.getState().setNestedProperty(['socketConnection', 'showConnectingScreen'], false);
        }
        return true;
    } catch (err) {
        throw err;
    }
};

const loadBaobabModelData = async (baobabPath) => {
    //state path is array which ends with model name = restApiModelName
    if (_.isArray(baobabPath)) {
        await multipageLoad(baobabPath, baobabPath[baobabPath.length - 1]);
    }
    return true;
};

export { loadStartupModelData, loadBaobabModelData };
