import React, { Component } from 'react';
//import _ from "lodash";
import PageTitle from '../../components/pageTitle';
import FormHeader from '../../components/formHeader';
import rest from '../../data/restWrapper';
import JSONPretty from 'react-json-pretty';
import pubsub from '../../data/pubsub';
import moment from 'moment';

class LicenceForm extends Component {
    constructor() {
        super();
        this.state = {
            licence: null,
            uniqueInstallationId: '',
            licenceKey: '',
            encodedLicence: '',
            remoteLicencePath: '',
            licenceType: 0, //0 no licence, 1 local licence, 2 remote licence
        };
    }
    async componentDidMount() {
        let licence = await rest('/licence');

        var licenceType = 0;
        if (licence.remoteLicencePath) {
            licenceType = 2;
        } else if (licence.licenceKey && licence.encodedLicence) {
            licenceType = 1;
        }

        this.setState({
            licence,
            uniqueInstallationId: licence.uniqueInstallationId,
            licenceKey: licence.licenceKey,
            encodedLicence: licence.encodedLicence,
            remoteLicencePath: licence.remoteLicencePath,
            licenceType,
        });
    }
    async updateLicence() {
        const { licenceKey, encodedLicence, remoteLicencePath } = this.state;
        try {
            await rest('/updateLicence', 'POST', {
                licenceKey,
                encodedLicence,
                remoteLicencePath,
            });
            return true;
        } catch (err) {
            console.log(err); //rest will toast the message
            return false;
        }
    }
    inputHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { licenceType } = this.state;

        var body2Render = null;

        if (licenceType === 0) {
            body2Render = <h3>{window.translate('No licence key')}</h3>;
        } else if (licenceType === 1) {
            //local
            body2Render = (
                <LocalLicenceLicenceType0
                    updateLicence={this.updateLicence.bind(this)}
                    setLicenceKey={(licenceKey) => {
                        this.setState({ licenceKey });
                    }}
                    setEncodedLicence={(encodedLicence) => {
                        this.setState({ encodedLicence });
                    }}
                    {...this.state}
                />
            );
        } else if (licenceType === 2) {
            //remote
            body2Render = (
                <RemoteLicenceLicenceType1
                    updateLicence={this.updateLicence.bind(this)}
                    setRemoteLicencePath={(remoteLicencePath) => {
                        this.setState({ remoteLicencePath });
                    }}
                    {...this.state}
                />
            );
        }

        return (
            <div className="bg-white p-3">
                <FormHeader>{window.translate('Licence form')}</FormHeader>
                <SelectLicenceType
                    setLicenceType={(licenceType) => {
                        this.setState({ licenceType });
                    }}
                    {...this.state}
                    {...this.props}
                />

                <hr />

                {body2Render}
            </div>
        );
    }
}

class LicenceInfo extends Component {
    constructor() {
        super();
        this.state = {
            licenceInfo: {
                licence: '',
            },
        };
    }
    async componentDidMount() {
        let licenceInfo = await rest('/licence');
        this.setState({ licenceInfo });
    }
    render() {
        const licenceValidUntil = moment(this.state.licenceInfo?.licence?.decodedLicence?.validTo).format("DD.MM.YYYY. HH:mm:ss")
        let licenceValid =
            this.state.licenceInfo && this.state.licenceInfo.licence && this.state.licenceInfo.licence.isValid
                ? this.state.licenceInfo.licence.isValid
                : false;
        let description =
            this.state.licenceInfo && this.state.licenceInfo.licence && this.state.licenceInfo.licence.description
                ? this.state.licenceInfo.licence.description
                : '';
        let decodedLicence =
            this.state.licenceInfo && this.state.licenceInfo.licence && this.state.licenceInfo.licence.decodedLicence
                ? this.state.licenceInfo.licence.decodedLicence
                : '{}';
        delete decodedLicence.uniqueInstallationId;
        return (
            <div className="bg-white p-3">
                <h3
                    className={
                        licenceValid
                            ? 'bg-white text-center border-bottom text-sucess p-2 m-0 uppercase'
                            : 'text-center bg-white border-bottom text-danger p-2 m-0 uppercase'
                    }
                >
                    {description}{licenceValid ? <span style={{fontWeight: "bold" ,textTransform: 'none', color: Date.now() > this.state.licenceInfo?.licence?.decodedLicence?.validTo ? 'red' : ""  }}> until: {licenceValidUntil}</span> : null}
                </h3>
                <div className="bg-white p-2 ">
                    <JSONPretty id="json-pretty" data={decodedLicence}></JSONPretty>
                </div>
            </div>
        );
    }
}

function Licence(props) {
    return (
        <div className="mobile-page">
            <PageTitle />

            <div className="row ">
                <div className="col-md-6 ">
                    <LicenceInfo />
                </div>
                <div className="col-md-6 ">
                    <LicenceForm {...props} />
                </div>
            </div>
        </div>
    );
}

const RemoteLicenceLicenceType1 = (props) => {
    const { remoteLicencePath, setRemoteLicencePath, updateLicence } = props;

    return (
        <div>
            <h5 className="mt-2">Remote licence:</h5>
            <input
                onChange={(e) => {
                    setRemoteLicencePath(e.target.value);
                }}
                name="remoteLicence"
                value={remoteLicencePath}
                placeholder={'Remote licence path'}
                type="text"
                className="form-control"
            />

            <button
                onClick={() => {
                    if (remoteLicencePath === '' || remoteLicencePath.startsWith('https://')) {
                        if (updateLicence()) {
                            pubsub.emit('toastMessage', 'Licence updated!');
                        }
                    } else {
                        pubsub.emit('toastMessage', window.translate('Licence path is not valid!'), 'error');
                    }
                }}
                className="btn btn-sm btn-primary mt-2 mr-2"
            >
                {window.translate('UPDATE LICENCE')}
            </button>

            <button
                style={{ textTransform: 'uppercase' }}
                onClick={() => {
                    setRemoteLicencePath('');
                    if (updateLicence()) {
                        pubsub.emit('toastMessage', 'Remote licence is removed!');
                    }
                }}
                className="btn btn-sm btn-outline-danger mt-2"
            >
                {window.translate('Delete')}
            </button>
        </div>
    );
};

const LocalLicenceLicenceType0 = (props) => {
    const { encodedLicence, licenceKey, setLicenceKey, setEncodedLicence, updateLicence, remoteLicencePath } = props;

    if (remoteLicencePath !== '') {
        return <h3>{window.translate('Remote licence is active !')}</h3>;
    }

    return (
        <div>
            <h5 className="mt-2">{window.translate('Licence key')}:</h5>

            <input
                readOnly={remoteLicencePath !== ''}
                onChange={(e) => {
                    setLicenceKey(e.target.value);
                }}
                name="licenceKey"
                value={licenceKey}
                placeholder={window.translate('Paste licence key here ...')}
                type="text"
                className="form-control"
            />

            <h5 className="mt-2">{window.translate('Encoded licence')}:</h5>

            <textarea
                readOnly={remoteLicencePath !== ''}
                onChange={(e) => {
                    setEncodedLicence(e.target.value);
                }}
                name="encodedLicence"
                value={encodedLicence}
                style={{ minHeight: '200px' }}
                placeholder={window.translate('Paste licence here ...')}
                className="form-control"
            ></textarea>

            <button onClick={updateLicence} className="btn btn-sm btn-primary mt-2 mr-2">
                {window.translate('UPDATE LICENCE')}
            </button>
        </div>
    );
};

const SelectLicenceType = (props) => {
    const { setLicenceType } = props;

    return (
        <div className="text-center">
            <button
                onClick={() => {
                    setLicenceType(1);
                }}
                className="btn btn-sm btn-warning mt-2 mr-2"
            >
                {window.translate('SET LOCAL LICENCE')}
            </button>

            <button
                onClick={() => {
                    setLicenceType(2);
                }}
                className="btn btn-sm btn-warning mt-2  mr-2"
            >
                {window.translate('SET REMOTE LICENCE PATH')}
            </button>

            <button
                onClick={() => {
                    props.history.push('/requestLicence');
                }}
                className="btn btn-sm btn-secondary mt-2"
            >
                {window.translate('REQUEST LICENCE')}
            </button>
        </div>
    );
};

export default Licence;